var search_form_index = document.getElementById('search_index');
if(search_form_index){
    search_form_index.addEventListener("submit", function(e){

        let day = $('#search_camion_day').val();
        let cuisine = $('#search_camion_cuisine').val();
        let city_name = $('#fake_search_camion_city').val();
        let city_id = $('#search_camion_city').val();
        localStorage.setItem('search_day',day );
        localStorage.setItem('search_cuisine',cuisine );
        localStorage.setItem('search_city_name',city_name );
        localStorage.setItem('search_city_id',city_id );
    });
}


$(document).ready(function () {
    if($("#home_search").is(":visible")){
         localStorage.setItem('search_day','' );
         localStorage.setItem('search_cuisine','' );
         localStorage.setItem('search_city_name','' );
         localStorage.setItem('search_city_id','' );
     }
     /*else{
        $('#search_camion_day').val(localStorage.getItem('search_day'));
        $('#search_camion_cuisine').val(localStorage.getItem('search_cuisine'));
        $('#fake_search_camion_city').val(localStorage.getItem('search_city_name'));
        $('#search_camion_city').val(localStorage.getItem('search_city_id'));
     }*/
});

var search_form_click = document.getElementById('index_click_collect');
if(search_form_click){
    search_form_click.addEventListener("submit", function(e){

        let category = $('#search_camion_category').val();
        let city_name = $('#fake_search_camion_city').val();
        let city_id = $('#search_camion_city').val();
        localStorage.setItem('search_category_click',category );
        localStorage.setItem('search_city_name_click',city_name );
        localStorage.setItem('search_city_id_click',city_id );
    });
}


