import KeenSlider from 'keen-slider';

var sliderElement = document.getElementById("my-keen-slider");
var interval = 0;

function autoplay(run) {
    clearInterval(interval);
    interval = setInterval(function () {
        if (run && slider) {
            slider.next();
        }
    }, 3000);
}

var slider = new KeenSlider(
    '#my-keen-slider',
    {
        loop: true,
        mode: "snap",
        centered: true,
        duration: 320000,
        slides: {
            perView: 3,
            spacing: 12,
            origin:'center',
        },
        breakpoints: {
            "(max-width: 1199px)": {
                slides: { perView: 2,  spacing: 24 },
            },
            "(max-width: 575px)": {
                slides: { perView: 1 },
            },

        },
    },
    [
        // add plugins here
    ]
)
slider.next();
autoplay(true);
/*$('.last-slider').slick({
        autoplay: true,
        button: false,
        prevArrow: false,
        nextArrow: false,
        autoplaySpeed: 1200,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '80px',

        // the magic
        responsive: [{
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                infinite: false
            }
        }, {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                dots: true
            }
        }, {
            breakpoint: 319,
            settings: "unslick" // destroys slick
        }]
    });*/


    /*$('.last-event').slick({
            autoplay: true,
            button: false,
            autoplaySpeed: 2500,
            infinite: true,
            speed: 8000,
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '80px',

            // the magic
            responsive: [{
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    infinite: false
                }
            }, {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }, {
                breakpoint: 319,
                settings: "unslick" // destroys slick
            }]
        });*/